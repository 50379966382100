import React from "react";
import jsonData from './data/db.json';

const ProjectContext = React.createContext();

class ProjectProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      events: [],
      eventsFilterValue: "",
      filteredEvents: [],
      loading: true,
    };
  }

  componentDidMount() {
      const { events } = jsonData;

      this.setState({
        events: events,
        filteredEvents: events,
        loading: false,
      });
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState(
      {
        eventsFilterValue: value,
      },
      this.filterEvents
    );
  };

  filterEvents = () => {
    let filteredEvents = [...this.state.events];
    // let { name, description, language, tag } = this.state;
    let value = this.state.eventsFilterValue;

    if (value !== "") {
      filteredEvents = filteredEvents.filter((item) => {
        if (
          item.name.toLowerCase().includes(value) ||
          item.description.toLowerCase().includes(value) ||
          item.date.includes(value)
        ) {
          console.log("HERE");
          return item;
        }
      });
    }

    this.setState({
      filteredEvents,
    });
  };

  getEvent = (slug) => {
    let events = this.state.events;
    const event = events.find((event) => event.slug === slug);
    if (event === undefined) {
      // Event not found
      return {
        status: 404,
        message: "Not found",
      };
    }
    return {
      status: 200,
      message: "Found",
      data: event,
    };
  };

  render() {
    return (
      <ProjectContext.Provider
        value={{
          ...this.state,
          getEvent: this.getEvent,
          handleChange: this.handleChange,
        }}
      >
        {this.props.children}
      </ProjectContext.Provider>
    );
  }
}

export { ProjectProvider, ProjectContext };
