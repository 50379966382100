import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import RenderParticles from "./RenderParticles";
// import { Parallax } from "react-scroll-parallax";

// Icons
import { AiOutlineInstagram, AiOutlineLinkedin } from "react-icons/ai";
import { SiCodechef } from "react-icons/si";
// import { FaDiscord } from "react-icons/fa";

// Components
import Footer from "../footer/Footer";
import Social from "./contact/socials/Social";
// const FlagshipEvent = React.lazy(() => import("./FlagshipEvent"));
// Images
// import codex-logo as "./images/logo/CODEX-FINAL-LOGO.png";

function Home() {
  return (
    <>
      <header className="home--header">
        <div className="home--header-container">
          <Navbar addClass="home--navbar" />
          <div className="home--header-content">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 col-md-9 h-100 d-flex flex-column justify-content-center">
                  <h1 className="title">
                    <Link to="/">
                      CODE<span className="transparent-text">X</span>
                      <br />
                      <span className="transparent-text">SFIT</span>
                    </Link>
                  </h1>
                  <p>CodeChef SFIT Chapter</p>
                </div>
              </div>
              <ul className="list-group social-icons d-none">
                {/* Instagram */}
                <li className="list-group-item">
                  <AiOutlineInstagram className="icon" />
                </li>
                <li className="list-group-item">
                  <AiOutlineLinkedin />
                </li>
                {/* Linkedin */}
                <li className="list-group-item">
                  <SiCodechef />
                </li>
              </ul>
            </div>
            <RenderParticles />
          </div>
        </div>
      </header>
      <div className="spacer"></div>
      <section id="about-short">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="logo-container img img-responsive">
                <img
                  src="http://codex.sfit.ac.in/media/images/logo/CODEX-FINAL-LOGO.png"
                  alt="codex logo"
                />
                {/* <img src="images/logo/codex.png" alt="" /> */}
              </div>
            </div>
            <div className="col-md-7">
              <div className="content">
                <div className="section-title-dark">
                  Your Efficient Algorithm to Success
                </div>
                <p className="description">
                  A home for coders. A guide to competitive programming. An
                  inspiration to do more. We are not an organization. We are a
                  family striving at helping our fellow mates in being more
                  technically sound and savvy. Join us to master various
                  technical genres and grow from a community of few to a fandom!
                  Let's CODE and EXplore together!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="flagship-event">
        <div className="container w-100 h-100">
          <div className="row">
            <div className="col-md-6">
              <div className="codexpress h-100 d-flex flex-column justify-content-center">
                <div className="section-title">CodeXpress</div>
                <p className="description">
                  "CODEXPRESS :  THE CLASH OF CODERS" is the most remarked
                  rendezvous a coder can look forward to. Codexpress is our much
                  anticipated yearly event which serves as a platform for
                  students to practise their coding skills and master the art of
                  Competetive Programming. This code-fest brings together and
                  challenges coders to rack their brains and develop not only
                  easy but efficient solutions to nerve-wracking problems. Stay
                  tuned for more announcements regarding CODEXPRESS 4.0!
                </p>
              </div>
            </div>
            <div className="decoration"></div>
            <div className="col-md-6">
              <div className="img-group h-100 d-flex flex-column justify-content-center">
                <div className="img-wrap">
                  <img
                    className="img img-fluid"
                    src="http://codex.sfit.ac.in/media/images/events/codexpress-4.png"
                    alt="Codexpress"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="section-title mb-3">Get in touch</div>
              <div className="group">
                <div className="Title">Email:</div>
                <span className="content">codex@sfit.ac.in</span>
              </div>

              <div className="group">
                <div className="title">Socials:</div>
                <Social />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Parallax className="custom-class" y={[-200, 200]} tagOuter="figure">
        <img src="images/terrain-01.svg" />
      </Parallax> */}
      <Footer />
    </>
  );
}

export default Home;
