import React from "react";

// Particles
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";



const RenderParticles = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        // background: {
        //   color: {
        //     value: "#0d47a1",
        //   },
        // },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },            
            resize: true,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 100,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            directions: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  );
};
// class RenderParticles extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       size: "large",
//       valueOfParticles: 60,
//     };
//   }

//   componentDidMount() {
//     window.addEventListener("resize", this.handleWindowSizeChange);
//     this.handleWindowSizeChange();
//   }

//   componentWillUnmount() {
//     window.removeEventListener("resize", this.handleWindowSizeChange);
//   }

//   handleWindowSizeChange = () => {
//     if (window.innerWidth < 576 && this.state.size !== "mobile") {
//       //   console.log("SETTINGS STATE to mobile" + this.state.valueOfParticles);
//       this.setState({
//         size: "mobile",
//         valueOfParticles: 30,
//       });
//     } else if (window.innerWidth > 576 && this.state.size !== "large") {
//       //   console.log("SETTINGS STATE" + this.state.valueOfParticles);
//       this.setState({
//         size: "large",
//         valueOfParticles: 60,
//       });
//     }
//   };

//   render() {
//     var valueOfParticles = this.state.valueOfParticles;
//     return (
//       <Particles
//         className="test"
//         params={{
//           polygon: {
//             enable: false,
//           },
//           move: {
//             speed: 100,
//           },
//           particles: {
//             number: {
//               value: valueOfParticles,
//             },
//           },
//         }}
//       />
//     );
//   }
// }

export default RenderParticles;
