import React from "react";
// import db from "./config/firebase-config";
import data from "./data/members.json";
const TeamsContext = React.createContext();

class TeamsProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      membersData: data,
      update: false,
      members: null,
      classList: ["title", "title-2", "title-3", "title-4"],
    };
  }

  createGrid = (slug) => {
    // let date = new Date();
    let currentTeam = this.state.membersData.members[slug.split("-")[0]];
    let teams = currentTeam.sections;
    let members = currentTeam.list;
    let gridElementList = [];
    let side = 0;

    teams.forEach((section, index) => {
      let placed = 0;
      let memberList = members.filter((member) => {
        if (member.group === section) {
          return member;
        }
      });

      memberList = [...memberList];
      let titleClass = this.getClass(memberList);

      let gridItems = memberList.map((member, index) => {
        return this.getMemberElement(member, index);
      });

      if (side === 0 || titleClass == "title-4") {
        gridItems.splice(
          0,
          0,
          this.getTitleElement(memberList.length, titleClass, section)
        );
      } else if (side === 1) {
        if (titleClass === "title-2") {
          gridItems.splice(
            2,
            0,
            this.getTitleElement(memberList.length, titleClass, section)
          );
        } else if (titleClass === "title-3") {
          gridItems.splice(
            1,
            0,
            this.getTitleElement(memberList.length, titleClass, section)
          );
        }
      }

      let gridElement = (
        <div
          className="member-group "
          id="core-group"
          key={index.toString() + section}
        >
          <div className="grid-container">{gridItems}</div>
        </div>
      );

      gridElementList = [...gridElementList, gridElement];
      if (side == 0) {
        side = 1;
      } else {
        side = 0;
      }
    });

    return gridElementList;
  };

  getMemberElement = (member, index) => {
    return (
      <div className="grid-item" key={index}>
        <div className="card">
          <div className="img-group">
            <img
              className="img img-fluid"
              src={`https://codex-sfit.github.io/assets/images/teams/${member.image}`}
              key={member.name + member.position}
              alt=""
            />
            
          </div>
          <div className="card-body">
            <div className="member-name">{member.name}</div>
            <div className="member-position">{member.position}</div>
          </div>
        </div>
      </div>
    );
  };

  getTitleElement = (length, titleClass, section) => {
    var title = length == 1 ? "item-1" : `item-${length}`;
    return (
      <div
        className={`grid-item title ${titleClass} ${title}`}
        key={titleClass + section}
      >
        <div className="member-group--title">
          <span>{section}</span>
          {/* <div className="glitch-wrapper">
            <div className="glitch" data-text={section}>
              <span>{section}</span>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  getClass = (memberList) => {
    let length = memberList.length;

    if (length == 1) {
      return "title-3";
    } else if ([2, 6, 10, 14, 18].includes(length)) {
      return "title-2";
    } else if ([3, 5, 9, 13, 17].includes(length)) {
      return "title-3";
    } else if (length >= 4) {
      return "title-4";
    }
  };

  getCurrentTeamData = (slug) => {
    return this.state.membersData.members[slug.split("-")[0]];
  };

  getDropDownList = () => {
    return this.state.membersData;
  };

  render() {
    return (
      <TeamsContext.Provider
        value={{
          ...this.state,
          createGrid: this.createGrid,
          getCurrentTeamData: this.getCurrentTeamData,
          getDropDownList: this.getDropDownList,
        }}
      >
        {this.props.children}
      </TeamsContext.Provider>
    );
  }
}

export { TeamsProvider, TeamsContext };
