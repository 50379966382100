import React from "react";

// Components
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

class NotFound extends React.Component {
  render() {
    return (
      <>
        <div className="header">
          <Navbar />
        </div>

        <main>
          <div className="container">
            <div className="jumbotron my-5">
              <h1 className="text-dark">404</h1>
              <h3 className="text-dark">Page not found</h3>
            </div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default NotFound;
