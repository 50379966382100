import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAzR5CLwyCshsd4ZTK7rmw3SQN7vZt2gEU",
  authDomain: "codex-99daa.firebaseapp.com",
  projectId: "codex-99daa",
  storageBucket: "codex-99daa.appspot.com",
  messagingSenderId: "570923085552",
  appId: "1:570923085552:web:464abf5a74959c4e491c35",
  measurementId: "G-FBGQK09YTC",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

export default app;
