import React from "react";

// Icons
import {
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiFillGithub,
} from "react-icons/ai";
import { SiCodechef } from "react-icons/si";
import { FaDiscord, FaWhatsapp } from "react-icons/fa";

function Social() {
  return (
    <>
      <div className="social-group"> {renderComponents()}</div>
    </>
  );
}

function renderComponents() {
  let data = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/codexsfit/",
    },
    {
      name: "Linkedin",
      link: "https://www.linkedin.com/company/codexsfit",
    },
    {
      name: "Discord",
      link: "https://discord.com/invite/yVMn4cX",
    },
    {
      name: "Github Organization",
      link: "https://github.com/CodeX-SFIT",
    },
    {
      name: "Whatsapp group",
      link: "https://chat.whatsapp.com/ICI9BNDHhuKLidngm7qwMY",
    },
    {
      name: "Codechef Student Chapter",
      link: "https://www.codechef.com/campus_chapter/SFIT",
    },
  ];

  //www.facebook.com/codexsfit/
  //www.codechef.com/college-chapter/member
  let components = data.map((item, index) => {
    return (
      <div className="social-item" key={index}>
        {/* <div className="social-title">Discord</div> */}
        <div className="sub-title">
          <span className="icon">
            {getIcon(item.name)}
            {/* get icon */}
            &nbsp;
          </span>
          <span className="content">
            <a href={item.link} target="_blank" rel="noreferrer">
              {item.name}
            </a>
          </span>
        </div>
      </div>
    );
  });

  return components;
}

function getIcon(name) {
  switch (name) {
    case "Discord":
      return <FaDiscord />;
    case "Github Organization":
      return <AiFillGithub />;
    case "Instagram":
      return <AiOutlineInstagram />;
    case "Whatsapp group":
      return <FaWhatsapp />;
    case "Codechef Student Chapter":
      return <SiCodechef />;
    case "Linkedin":
      return <AiOutlineLinkedin />;
    default:
      return <FaDiscord />;
  }
}

export default Social;
