import React from "react";
import { Route, Switch } from "react-router-dom";
import "./config/firebase-config.js";
import { ProjectProvider } from "./context";
import { TeamsProvider } from "./teams_context";
import Particles from 'react-particles';

// Components
import Home from "./components/home/Home";

import NotFound from "./components/error/NotFound";
const About = React.lazy(() => import("./components/about/About"));
const Events = React.lazy(() => import("./components/events/Events"));
const Team = React.lazy(() => import("./components/team/Team"));
const EventDetail = React.lazy(() =>
  import("./components/eventDetail/EventDetail")
);
const Links = React.lazy(() => import("./components/links/Links"));

function App() {
  return (
    <>
        <ProjectProvider>
          <TeamsProvider>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/events" component={Events} />
              <Route path="/events/:eventSlug" component={EventDetail} />
              <Route exact path="/team">
                <Team default={true} />
              </Route>
              <Route path="/team/:slug" component={Team} />
              <Route exact path="/links" component={Links} />
              <Route path="*" component={NotFound} />
            </Switch>
          </TeamsProvider>
        </ProjectProvider>
    </>
  );
}

export default App;
