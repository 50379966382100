import React from "react";

function Footer() {
   return (
  //   <footer>
  //     <div className="container py-3">
  //       <div className="row">
  //         <div className="col-sm-6 col-md-3">
  //           <p className="footer-subheading">Navigation</p>
  //           <ul className="list-unstyled">
  //             <li>
  //               <a href="/">Home</a>
  //             </li>
  //             <li>
  //               <a href="/about">About</a>
  //             </li>
  //             <li>
  //               <a href="/team">Teams</a>
  //             </li>
  //             <li>
  //               <a href="/events">Events</a>
  //             </li>
  //           </ul>
  //         </div>

  //         {/* social */}
  //         <div className="col-sm-6 col-md-3">
  //           <p className="footer-subheading">Connect with us</p>
  //           <ul className="list-unstyled">
  //             <li>
  //               <a
  //                 href="https://www.instagram.com/codexsfit/"
  //                 target="_blank"
  //                 rel="noreferrer"
  //               >
  //                 Instagram
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="https://www.linkedin.com/company/codexsfit"
  //                 target="_blank"
  //                 rel="noreferrer"
  //               >
  //                 Linkedin
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="https://github.com/CodeX-SFIT"
  //                 target="_blank"
  //                 rel="noreferrer"
  //               >
  //                 Github Organization
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="https://www.codechef.com/campus_chapter/SFIT"
  //                 target="_blank"
  //                 rel="noreferrer"
  //               >
  //                 Codechef Student Chapter
  //               </a>
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
      <div className="text-center pb-4">
        © 2022 Copyright: CodeX SFIT
      </div>
  //     {/* <div className="text-center pb-3">
  //       Website by:&nbsp;
  //       <a className="" href="https://alandsilva.netlify.app">
  //         Alan Dsilva
  //       </a>
  //     </div> */}
  //   </footer>
  );
}

export default Footer;
