import React from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addClass: this.props.addClass,
      current: this.props.current,
      navbarState: 0,
    };
  }

  handleClick = () => {
    this.setState({
      navbarState: this.state.navbarState === 0 ? 1 : 0,
    });
  };

  render() {
    let addClass = this.state.addClass !== undefined ? this.state.addClass : "";
    let state = this.state.navbarState === 0 ? "closed" : "opened";
    return (
      <nav className={"navbar navbar-expand-md " + addClass + " " + state}>
        <Link className="navbar-brand" to="/">
          CodeX | SFIT
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            this.handleClick();
          }}
        >
          {/* <span class="navbar-toggler-icon"></span> */}
          <GiHamburgerMenu className="toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/team">
                Team
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/events">
                Events
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
